import React, { useState, useEffect } from 'react'
import '_appSetup/Global.css'
import { makeStyles, withStyles } from '@material-ui/styles'
import { Grid, Tabs, Tab, CircularProgress } from '@material-ui/core'
import { Table, SEO, View, Text, WhiteGreyLayout, Page, Button, analytics } from 'lib'
import firebase from 'gatsby-plugin-firebase'
import { Capacitor } from '@capacitor/core'
import { Link, navigate } from 'gatsby'
import moment from 'moment'
import { fetchUserData } from 'actions/User'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { calculateInterest, useUserData, formatUSD, formatAPY, roundDownToDecimals } from './_utils'

import { OnboardingDialog } from './_dialogs'
import { showRatingPrompt } from './_rating'

const periods = [
  { text: '10 years', days: 365 * 10 },
  { text: 'Year', days: 365 },
  { text: 'Month', days: 30 },
  { text: 'Day', days: 1 },
]

const Dashboard = (props) => {
  const styles = useStyles(props)

  const [selectedTab, setSelectedTab] = useState(1)
  const [onboardingDialogVisible, setOnboardingDialogVisible] = useState(false)

  const { contributions, UserData } = useUserData()

  const { balance, APY } = UserData

  const UserDataLoading = !UserData || !UserData.email

  const onboarding = async () => {
    const onboardingUpdates = {}

    if (balance == 0 && !UserData.onboarding?.onboardingDialogViewed
    ) {
      onboardingUpdates.onboardingDialogViewed = true
      setOnboardingDialogVisible(true)
      if (window && typeof window !== 'undefined' && !__DEV__) window.fbq('track', 'Signed up and verified', { email: UserData.email })
    }

    // ask for a review after the user has been around for a while & likely to have had a good experience
    if (Capacitor.isNativePlatform() &&
        !(UserData.onboarding?.appStoreRatingRequested) &&
        balance > 500 &&
        moment(firebase.auth().currentUser?.metadata?.creationTime).isBefore(moment().subtract(35, 'days'))) {

      showRatingPrompt()
      onboardingUpdates.appStoreRatingRequested = true
    }

    if (Object.keys(onboardingUpdates).length) {
      const updateOnboardingPropsFunc = firebase.functions().httpsCallable('updateOnboardingProps')
      await updateOnboardingPropsFunc(onboardingUpdates)
      fetchUserData({})
    }

  }

  useEffect(() => {
    if (!UserData.email) return
    onboarding()
  }, [UserDataLoading])

  const balanceString = formatUSD(balance, true)

  const actionItems = []

  if (!UserData.lunaWithdrawalDetails) {

    actionItems.push([
      'Confirm your LUNA airdrop withdrawal address.',
      <Button variant='contained' onClick={() => navigate('/withdrawLuna')} style={styles.withdrawButton}>Confirm address</Button>,
    ])

  }

  const USTRow = ['UST', balanceString]

  if (balance >= 0.01) {
    const USTWithdrawButton = <Button variant='contained' onClick={() => navigate('/withdraw')} style={styles.withdrawButton}>Withdraw</Button>

    USTRow.push(USTWithdrawButton)

    if (balance >= 10) {
      actionItems.push([
        'Withdraw your remaining UST.',
        USTWithdrawButton,
      ])
    }

  } else {
    USTRow.push(<Text text='-'/>)
  }

  const LunaRow = ['LUNA', UserData.balanceLuna >= 0.01 ? UserData.balanceLuna : 0]

  if (UserData.balanceLuna >= 0.01) {
    const LunaWithdrawButton = <Button variant='contained' onClick={() => navigate('/withdrawLuna')} style={styles.withdrawButton}>Withdraw</Button>

    LunaRow.push(LunaWithdrawButton)

  } else {
    LunaRow.push(<Text text='-'/>)
  }

  const tableData = [USTRow, LunaRow]


  const LeftComponent = () => {
    return (
      <View>
        <View style={styles.topWrapper}>
          {UserDataLoading ? <CircularProgress size={40}/> : (
            <>

              {UserData.EMERGENCY_MODE && (
                <Grid item xs={12}>
                  <Text variant='subtitle2' style={styles.shutdownDisclaimer}>We discontinued the Stablegains service. Please withdraw your remaining assets. Details <a target='_blank' href='https://blog.stablegains.com/were-discontinuing-the-stablegains-service-please-withdraw-your-remaining-funds-405e6bfb89c4'>here</a>.</Text>
                </Grid>
              )}

              {actionItems.length ? (
                <>
                  <Text variant='h6' style={styles.tableHeading}>Action required</Text>
                  <Table head={['Action Item', 'Actions']} rows={actionItems}/>
                </>
              ) : (
                <>
                  <Text variant='h6' style={styles.tableHeading}>Remaining balance</Text>

                  <Table head={['Asset', 'Available balance', 'Actions']} rows={tableData} noDataCaption="We haven't received deposits from you yet."/>
                </>
              )}

              {!UserData.EMERGENCY_MODE && (
                <>
                  <Button variant='contained' style={styles.depositButton} color='secondary' text={'Make a deposit'} onClick={() => {
                    analytics.track('Make Deposit Button (Home)')
                    navigate('/deposit')
                  } }/>
                  {balance > 0 && <Button variant='outlined' style={styles.referralCTAButton} color='secondary' text={'Earn more with referrals'} onClick={() => navigate('/referrals')}/>}
                </>
              )}
            </>
          )}
        </View>

        {/* <Hidden xsDown>
          <View style={styles.bottomWrapper}>
            <View style={styles.perfSpacer}/>
            <Grid container className={styles.performanceWrapper} alignItems='center'>
              <Grid item xs={12} className={styles.spaceBottom}>
                <Text className={styles.perfLabel}>PERFORMANCE</Text>
              </Grid>
              <PerformanceData/>
            </Grid>
          </View>
        </Hidden> */}
      </View>
    )
  }

  return (
    <Page>
      <SEO title='Dashboard'/>
      <WhiteGreyLayout
        leftComponent={LeftComponent}
        rightComponent={() => null}
      />

      <OnboardingDialog
        open={onboardingDialogVisible}
        setOpen={setOnboardingDialogVisible}
      />
    </Page>
  )
}

const TabEdited = ({ classes, ...props }) => <Tab classes={{ root: classes.tab }} {...props}/>
const StyledTab = withStyles({
  tab: {
    minWidth: 90,
    maxWidth: 90,
    width: 90,
  },
})(TabEdited)


const useStyles = makeStyles((theme) => ({
  topWrapper: {
    minHeight: '25vh',
    paddingTop: '50px',
    paddingBottom: '30px',
    width: '100%',
    ...theme.center,
    display: 'flex',
    flexDirection: 'column',
  },
  APYwrapper: {
    [theme.breakpoints.down('xs')]: {
      minHeight: '15vh',
      paddingTop: '30px',
      paddingBottom: '20px',
    },
  },
  depositButton: {
    marginTop: theme.spacing(1.5),
  },
  referralCTAButton: {
    marginTop: theme.spacing(0.5),
  },
  newDepositNote: {
    opacity: 0.5,
    textAlign: 'center',
  },
  bottomWrapper: {
    ...theme.center,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: theme.spacing(8),
  },
  performanceWrapper: {
    maxWidth: '532px',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  spaceBottom: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  perfLabel: {
    // fontSize: 12,
  },
  perfSpacer: {
    marginTop: theme.spacing(3),
    borderTop: '1px solid #00000016',
    paddingTop: theme.spacing(8),
    alignSelf: 'stretch',
  },
  tableSpacer: {
    borderTop: '1px solid #00000016',
    alignSelf: 'stretch',
    paddingTop: theme.spacing(2),
  },
  value: {
    fontSize: 18,
  },
  expectedInterest: {
    marginTop: theme.spacing(7),
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
    },
  },
  earnExtra: {
    fontSize: 12,
  },
  referralBox: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(2),
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(6),
  },
  mobilePerformanceWrapper: {
    marginLeft: `${theme.spacing(2)}px !important`,
    marginRight: `${theme.spacing(2)}px !important`,
    marginBottom: `${theme.spacing(6)}px !important`,
  },
  mobilePerformanceContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
  },
  shutdownDisclaimer: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(3),
    color: 'red',
  },
  withdrawButton: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  tableHeading: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
}))

export default Dashboard
