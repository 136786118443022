import { Dialog } from '@capacitor/dialog'
import { navigate } from 'gatsby'
import { RateApp } from 'capacitor-rate-app'

export const showRatingPrompt = async () => {
  const confirm1 = await Dialog.confirm({
    title: 'Your experience so far',
    message: `Has your experience with Stablegains been positive?`,
    okButtonTitle: 'Yes!',
    cancelButtonTitle: 'Not really',
  })

  if (confirm1.value == true) {

    RateApp.requestReview()

  } else {

    const confirm2 = await Dialog.confirm({
      title: `We're sorry to hear that!`,
      message: `We would love to understand what we can do to make Stablegains better for you.`,
      okButtonTitle: 'Contact the team',
      cancelButtonTitle: 'Dismiss',
    })

    if (confirm2.value == true) {
      navigate('/support')
    }

  }
}
