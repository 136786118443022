import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { View, Button, Text } from 'lib'
import firebase from 'gatsby-plugin-firebase'
import { navigate } from 'gatsby'

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'

export const OnboardingDialog = ({
  open,
  setOpen,
  successCallback = () => null,
}) => {
  const styles = useStyles()

  const closeModal = () => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={() => closeModal()}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{`Welcome to your Stablegains dashboard`}
      </DialogTitle>
      <DialogContent>

        <Text gutterBottom>Start earning high interest on your savings today. Click below to get started!</Text>

      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => navigate('/deposit')}
          variant='contained'>
            Make your first deposit
        </Button>
        <Button
          onClick={() => closeModal()}>
            Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
}))

export default OnboardingDialog
